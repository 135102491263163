import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dashboard"
export default class extends Controller {
  static targets = [ "users", "lessons", "links"]

  connect(e) {
    const query = window.location.search.split("=")[1];
    if (query && query === "clientes") {
      this.linksTarget.querySelectorAll("a").forEach((link) => {
        if (link.classList.contains("active")) {
          link.classList.remove("active");
        } else {
          link.classList.add("active");
        }
      });
      this.usersTarget.classList.remove("none");
      this.lessonsTarget.classList.add("none");
    }
  }

  showSeats(e) {
    e.target.classList.toggle("clicked");
  }

  updateDashboardLessons(e) {
    const date = e.target.value;
    fetch(`/lesson-dashboard?date=${date}&studio_id=${e.target.dataset.studio}`, {
      method: "GET",
      headers: {
        "X-CSRF-Token": document.querySelector('[name="csrf-token"]').content,
        "accept": "text/plain"
      }
    }).then(response => response.text())
      .then((data) => {
        this.lessonsTarget.innerHTML = data;
      });
  }

  displayUsers(e) {
    e.preventDefault();
    this.linksTarget.querySelectorAll("a").forEach((link) => {
      if (link.classList.contains("active")) {
        link.classList.remove("active");
      } else {
        e.target.classList.add("active");
      }
    });
    this.usersTarget.classList.remove("none");
    this.lessonsTarget.classList.add("none");

  }

  displayLessons(e) {
    e.preventDefault();
    this.linksTarget.querySelectorAll("a").forEach((link) => {
      if (link.classList.contains("active")) {
        link.classList.remove("active");
      } else {
        e.target.classList.add("active");
      }
    });
    this.usersTarget.classList.add("none");
    this.lessonsTarget.classList.remove("none");
  }

  displayReservationForm(e) {
    e.preventDefault();
    const tr = e.target.parentElement.parentElement;
    tr.querySelectorAll(".none").forEach((element) => {
      element.classList.remove("none");
    });
    e.target.classList.add("none");
  }

  blockSeat(e) {
    e.preventDefault();
    const formTr = e.target.parentElement;
    const seatId = formTr.dataset.seatId;
    const comment = formTr.querySelector(".comment").value;
    fetch(`/block-seat/${seatId}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('[name="csrf-token"]').content,
        "accept": "application/json"
      },
      body: JSON.stringify({ comment: comment })
    }).then(response => response.json())
      .then((data) => {
        if (data.comment) {
          formTr.querySelector(".r-comment").innerText = data.comment;
          formTr.querySelector(".r-seat").innerText = data.seat_number;
          formTr.querySelector(".r-action").innerHTML = `<a class="dashboard-a" data-turbo-method="delete" data-turbo-confirm="seguro?" href="/reservations/${data.id}">Cancelar</a>`;
        } else {
          window.alert(data.error);
        }
      });
  }

  addCredit(e) {
    e.preventDefault();
    fetch(e.target.parentElement.href, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('[name="csrf-token"]').content,
        "accept": "application/json"
      }
    })
      .then(response => response.json())
      .then((data) => {
        const credits = e.target.parentElement.parentElement.querySelector(".user-credit-number")
        credits.innerText = data.lessons_number;
        const lessonsNumber = Number.parseInt(credits.innerText, 10);
        if (lessonsNumber > 0) {
          const removeCreditButton = e.target.parentElement.parentElement.querySelector(".remove-credit-btn");
          removeCreditButton.classList.remove("none");
        }
      });
  }

  removeCredit(e) {
    e.preventDefault();
    fetch(e.target.parentElement.href, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('[name="csrf-token"]').content,
        "accept": "application/json"
      }
    })
      .then(response => response.json())
      .then((data) => {
        const credits = e.target.parentElement.parentElement.querySelector(".user-credit-number")
        credits.innerText = data.lessons_number;
        const lessonsNumber = Number.parseInt(credits.innerText, 10);
        if (lessonsNumber === 0) {
          const removeCreditButton = e.target.parentElement.parentElement.querySelector(".remove-credit-btn");
          removeCreditButton.classList.add("none");
        }
      });
  }
 }

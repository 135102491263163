import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="profile"
export default class extends Controller {
  static targets = [ "clases", "tituloClases" ]

  previousLessons(e)  {
    e.preventDefault();
    const url = e.currentTarget.href;
    fetch(url, {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content,
        "Content-Type": "text/plain",
        "Accept": "text/plain"
      }
    })
      .then(response => response.text())
      .then((data) => {
        this.clasesTarget.innerHTML = data;
        this.tituloClasesTarget.innerText = "Clases Previas";
        e.target.outerHTML = "<a href='/next-lessons' data-action='click->profile#nextLessons'>Próximas Clases</a>";
      });
  }

  nextLessons(e)  {
    e.preventDefault();
    const url = e.currentTarget.href;
    fetch(url, {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content,
        "Content-Type": "text/plain",
        "Accept": "text/plain"
      }
    })
      .then(response => response.text())
      .then((data) => {
        this.clasesTarget.innerHTML = data;
        this.tituloClasesTarget.innerText = "Próximas Clases";
        e.target.outerHTML = "<a href='/previous-lessons' data-action='click->profile#previousLessons'>Clases Previas</a>";
      });
    }
}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="summary"
export default class extends Controller {

  confirmAttendance(e) {
    e.preventDefault();
    fetch(e.target.parentElement.attributes.href.value, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
        "accept": "application/json"
      }
    }).then(response => response.json())
      .then((data) => {
        if (data.message === "success") {
          e.target.parentElement.classList.add("none");
          e.target.parentElement.parentElement.querySelector(".cancel").classList.remove("none");
        }
    })
  }

  cancelAttendance(e) {
    e.preventDefault();
    fetch(e.target.parentElement.attributes.href.value, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
        "accept": "application/json"
      }
    }).then(response => response.json())
      .then((data) => {
        if (data.message === "success") {
          e.target.parentElement.classList.add("none");
          e.target.parentElement.parentElement.querySelector(".confirm").classList.remove("none");
        }
    })
  }


}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="lessons"
export default class extends Controller {
  static targets = [ "lessons" ]

  updateLessons(e) {
    fetch(`/lessons?filtro=${e.target.value}`, {
      headers: {
        'Accept': 'text/plain',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      }
    })
      .then(response =>  response.text())
      .then((data) => {
        this.lessonsTarget.outerHTML = data;
      });
  }

  updateDates(e) {
    fetch(`/lessons?date=${e.target.value}&studio_id=${e.target.dataset.studio}`, {
      headers: {
        'Accept': 'text/plain',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      }
    })
      .then(response =>  response.text())
      .then((data) => {
        this.lessonsTarget.outerHTML = data;
      });
  }

  selectSeat(e) {
    if (!e.target.classList.contains("ocupado")) {
      document.querySelectorAll(`.lesson-${e.target.dataset.lessonId}-seat`).forEach((seat) => {
        if (seat.classList.contains("selected") && seat != e.target) {
          seat.classList.remove("selected")
        }
      })
      e.target.classList.toggle("selected")
    }
  }

  confirmReservation(e) {
    e.preventDefault()
    const selectedSeat = document.querySelector(".selected")
    if (selectedSeat){
      const lessonId = selectedSeat.dataset.lessonId
      const seatNumber = selectedSeat.innerText
      const hiddenFieldSeat = `<input type="hidden" name="seat_number" value="${seatNumber}">`
      const form = document.querySelector(`.lesson-${lessonId}-form`)
      form.insertAdjacentHTML("beforeend", hiddenFieldSeat)
      form.submit();
    }
  }

  displaySeatInfo(e) {
    const email = e.target.querySelector(".reservation-email")
    if (email) {
      const seat = e.target.querySelector(".lesson-seat-number")
      email.classList.toggle("none")
      seat.classList.toggle("none")
    }
  }
}

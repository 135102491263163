import { Controller } from "@hotwired/stimulus"


// Connects to data-controller="packages"
export default class extends Controller {
  static targets = ["package"]

  purchasePackage(e) {
    const packageDiv = e.target.closest('[data-packages-target="package"]');
    const form = packageDiv.querySelector('form');
    if (form) {
      form.submit();
    } else {
      console.error('Form not found');
    }
  }
}

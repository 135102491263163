import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="nav-menu"
export default class extends Controller {
  static targets = [ "menu", "navbtn", "loginModalBody", "signInModalBody", "signInPartOne", "signInPartTwo", "signInTitle", "signInBtn" ]

  toggleMenu(e) {
    if (this.menuTarget.classList.contains("active")) {
      this.navbtnTarget.innerHTML = `
        <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15.8333 10.8375H33.25C33.6047 10.8375 33.9449 10.6966 34.1958 10.4458C34.4466 10.1949 34.5875 9.85473 34.5875 9.5C34.5875 9.14527 34.4466 8.80507 34.1958 8.55424C33.9449 8.30341 33.6047 8.1625 33.25 8.1625H15.8333C15.4786 8.1625 15.1384 8.30341 14.8876 8.55424C14.6367 8.80507 14.4958 9.14527 14.4958 9.5C14.4958 9.85473 14.6367 10.1949 14.8876 10.4458C15.1384 10.6966 15.4786 10.8375 15.8333 10.8375ZM34.5875 19C34.5875 18.6453 34.4466 18.3051 34.1958 18.0542C33.9449 17.8034 33.6047 17.6625 33.25 17.6625H4.75C4.39527 17.6625 4.05507 17.8034 3.80424 18.0542C3.55341 18.3051 3.4125 18.6453 3.4125 19C3.4125 19.3547 3.55341 19.6949 3.80424 19.9458C4.05507 20.1966 4.39527 20.3375 4.75 20.3375H33.25C33.6047 20.3375 33.9449 20.1966 34.1958 19.9458C34.4466 19.6949 34.5875 19.3547 34.5875 19ZM23.5042 28.5C23.5042 28.1453 23.3633 27.8051 23.1124 27.5542C22.8616 27.3034 22.5214 27.1625 22.1667 27.1625H4.75C4.39527 27.1625 4.05507 27.3034 3.80424 27.5542C3.55341 27.8051 3.4125 28.1453 3.4125 28.5C3.4125 28.8547 3.55341 29.1949 3.80424 29.4458C4.05507 29.6966 4.39527 29.8375 4.75 29.8375H22.1667C22.5214 29.8375 22.8616 29.6966 23.1124 29.4458C23.3633 29.1949 23.5042 28.8547 23.5042 28.5Z" fill="white" stroke="white" stroke-width="0.3"/>
        </svg>
      `
    } else {
      this.navbtnTarget.innerHTML = `
        <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2.50144 29.0807L14.584 17L2.50144 4.91654C1.83414 4.2501 1.83353 3.16896 2.50008 2.50176C3.16662 1.83456 4.24792 1.83395 4.91522 2.5004L17.0005 14.5838L29.083 2.5004C29.7503 1.8332 30.8322 1.8332 31.4995 2.5004C32.1668 3.1676 32.1668 4.24934 31.4995 4.91654L19.417 17L31.4995 29.0834C31.9312 29.515 32.0997 30.1441 31.9417 30.7337C31.7837 31.3233 31.3232 31.7838 30.7335 31.9417C30.1438 32.0997 29.5147 31.9312 29.083 31.4996L17.0005 19.4161L4.91794 31.4996C4.24989 32.1668 3.16737 32.1662 2.50008 31.4982C1.83278 30.8302 1.83339 29.7479 2.50144 29.0807Z" fill="white" stroke="black" stroke-opacity="0.01" stroke-width="2.81835"/>
        </svg>`
    }
    this.menuTarget.classList.toggle("active")
    this.navbtnTarget.classList.toggle("clicked")
  }

  toggleLogin(e) {
    e.preventDefault()
    this.loginModalBodyTarget.classList.add("none");
    this.signInModalBodyTarget.classList.remove("none");
  }

  toggleSignIn(e){
    e.preventDefault()
    this.signInModalBodyTarget.classList.add("none");
    this.loginModalBodyTarget.classList.remove("none");
  }

  signInNext(e){
    e.preventDefault()
    this.signInPartOneTarget.classList.add("none");
    this.signInPartTwoTarget.classList.remove("none");
    this.signInBtnTarget.classList.remove("none");
    this.signInTitleTarget.innerText = "Cuéntanos un poco de ti"
    e.target.style.display = "none";
  }
}

import { Controller } from "@hotwired/stimulus"
import Pickr from '@simonwep/pickr';

export default class extends Controller {
  connect() {
    const pickr = Pickr.create({
      el: this.element,
      theme: 'classic', // or use other available themes
      default: '#00FF2A', // set initial color value
      comparison: false, // disable color comparison mode
      components: {
        preview: true,
        opacity: true,
        hue: true,
        interaction: {
          hex: true, // display hexadecimal value
          rgba: true, // display RGBA value
          input: true, // enable manual input
          clear: true, // enable clear button
          save: true, // enable save button
          cancel: false, // enable cancel button
        },
      },
    });
    pickr.on('save', (color) => {
      this.updateValue(color);
    });
  }

  updateValue(color) {
    // insert a hidden field with the color value
    const hiddenField = document.querySelector('#category-color');
    if (hiddenField) {
      hiddenField.value = color.toHEXA().toString();
    } else {
      document.querySelector('form').insertAdjacentHTML('beforeend', `<input type="hidden" id="category-color" name="${this.element.name}" value="${color.toHEXA().toString()}">`);
    }
  }
}
